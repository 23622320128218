<template>
    <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <logo-text />
        </b-link>

        <b-card-title class="mb-1">
          Welkom bij Tapp! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Meld je aan met je Tapp-account!
        </b-card-text>
        <login />
      </b-card></div></div>
</template>

<script>

import {BCard, BCardText, BCardTitle, BLink} from "bootstrap-vue";
import LogoText from "@core/layouts/components/LogoText.vue";
import Login from './Login.vue';
export default {
    components: {
        LogoText,
        Login,
        BCard, BCardText, BCardTitle, BLink
    }
}
</script>

<style>

</style>